import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from '../_services/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bookmodal',
  templateUrl: './bookmodal.component.pug',
  styleUrls: ['./bookmodal.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-in',
                    style({ height: 76, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 50, opacity: 1 }),
            animate('0.5s ease-out',
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BookmodalComponent implements OnInit {

  data:any;
  private getData: Subscription;

  bookingDates:any[];

  productId = 0;
  dateId = 0;
  formStep = 0;
  formInput = 0;
  formType;
  bookingTime;
  productStep = 1;
  platformsQuestionnaire: FormGroup;
  products = [{
    productimg: "product1-lg",
    productname: "Space Platforms",
    slide: "1"
  },
  {

    productimg: "product2-lg",
    productname: "Satellite Deployments",
    slide: "2"
  },
  {

    productimg: "product3-lg",
    productname: "In-Space Services",
    slide: "3"
  },
  {

    productimg: "product4-lg",
    productname: "Ground Segment Services",
    slide: "4"

  },
  ]

  dates = [{
    year: "2021",
    quarter: "Q3",
    id: "1"
  },
  {
    year: "2021",
    quarter: "Q4",
    id: "2"
  },
  {
    year: "2022",
    quarter: "Q1",
    id: "3"
  },
  {
    year: "2022",
    quarter: "Q2",
    id: "4"
  },
  {
    year: "2022",
    quarter: "Q3",
    id: "5"
  },
  {
    year: "2022",
    quarter: "Q4",
    id: "6"
  },

  {
    year: "2023",
    quarter: "Q1",
    id: "7"
  },

  {
    year: "2023",
    quarter: "Q2",
    id: "8"
  },

  {
    year: "2023",
    quarter: "Q3",
    id: "9"
  },
  ];

  platformsFormSet = [

  {
    label: 'Name',
    formname: 'name',
    placeholder: 'Enter your full name'
  },
  {
    label: 'Role',
    formname: 'role',
    placeholder: 'Enter your role at company'
  },
  {
    label: 'Email',
    formname: 'email',
    placeholder: 'Enter your email address'
  },
  {
    label: 'Phone',
    formname: 'phone',
    placeholder: 'Enter your phone number'
  },
  {
    label: 'Company Name',
    formname: 'companyname',
    placeholder: 'Enter your company name'
  },
  {
    label: 'Company Address',
    formname: 'companyaddress',
    placeholder: 'Enter your company address'
  },
  {
    label: 'Payload Type',
    formname: 'payloadtype',
    placeholder: 'Enter your company type'
  },
  {
    label: 'Payload Size',
    formname: 'payloadsize',
    placeholder: 'Enter your payload size'
  }
]

  dateOptions: OwlOptions = {
    loop: false,
    center: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    items: 1,
    responsive: {
      1100: {
        items: 4
      },
    },
    autoplay: false,
    slideTransition: 'linear',
  };

  customOptions: OwlOptions = {
    loop: false,
    center: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    items: 4,
    autoplay: false,
    slideTransition: 'linear',
  }


  // platformsForm() {
  //   if(this.productId === 0) {
  //     this.formType = "Space Platforms"
  //   }
  //   if(this.productId === 1) {
  //     this.formType = "Satellite Deployments"
  //   }
  //   if(this.productId === 2) {
  //     this.formType = "In-Space Services"
  //   }
  //   if(this.productId === 3) {
  //     this.formType = "Ground Segment Services"
  //   }

  //   let slot = this.dates[this.dateId];
  //   this.bookingTime = `${slot.quarter} ${slot.year}`;

  //   this.platformsQuestionnaire = this.formBuilder.group({
  //     name: [''],
  //     email: [''],
  //     phone: [''],
  //     companyname: [''],
  //     // Questions
  //   })
  // }


  getDateDetails() {
    let slot = this.dates[this.dateId];
    this.bookingTime = `${slot.quarter} ${slot.year}`;
  }

  previousFormStep() {
    this.formStep = this.formStep - 1;
  }

  nextFormStep() {
    this.formStep = this.formStep + 1;
  }

  updateProductSlide(i) {
    this.productId = i;
  };

  updateDateSlide(i) {
    this.dateId = i;
  }

  focusInput() {

  }

  constructor( private formBuilder: FormBuilder, public modal: ModalService, public renderer: Renderer2) { }

  ngOnInit() {
    const d = new Date();
    let m = d.getMonth();
    const y = d.getFullYear();
    const q = Math.floor((m+1)/3);
    let dates = [];
    for(let i=0; i<10; i++) {
      dates.push({
        year: Math.floor((q+i)/4)+y,
        quarter: 'Q'+((q+i)%4+1),
        id: i.toString()
      });
    }
    this.dates = dates;
    // this.platformsForm();
    // this.getFormType();

    this.getData = this.modal.getData().subscribe(data => {
      if(data !== 'step1') {
        console.log(data);
        this.productStep = 2;
        this.productId = data;
      } else {
        this.productStep = 1;
        this.productId = 0;
      }
    });

    this.getDateDetails();
  }

  ngOnDestroy() {
    this.getData.unsubscribe();
  }

}
