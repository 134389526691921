import { Component, OnInit, HostListener } from '@angular/core';
import * as AOS from 'aos';
import { IconDefinition, IconService, ThemeType } from '@ant-design/icons-angular';
import { MailFill, TwitterOutline, LinkedinFill, EnterOutline, ArrowUpOutline} from '@ant-design/icons-angular/icons';
import { ModalService } from './_services/modal.service';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private _iconService: IconService, public modal: ModalService, private router: Router) {
    this._iconService.addIcon(...[ MailFill, TwitterOutline, LinkedinFill, EnterOutline, ArrowUpOutline]);
    this._iconService.twoToneColor = { primaryColor: '#1890ff' };
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'UA-207032290-1', {
          'page_path': event.urlAfterRedirects
        });
      }
    });
  }
  
  title = 'orbital';

  onActivate(event) {
    window.scroll(0, 0);
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }


  navMenuOpen = false;
  currentSection: any;
  isScrolled = false;

  @HostListener("window:scroll")
  scrollEvent() {
    window.pageYOffset >= 80 ? (this.isScrolled = true) : (this.isScrolled = false);
  }

  ngOnInit() {
    AOS.init({once: true});
  }
}
