import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'satellite-deployments-form',
  templateUrl: './satellite-deployments-form.component.pug',
  styleUrls: ['./satellite-deployments-form.component.scss']
})
export class SatelliteDeploymentsFormComponent implements OnInit {

  @Input() date;

  electricalOptions =
  ["Nominal (120VDC, 350 watts)", "Maximum (700 watts)", "Other power and electrical considerations"]

  materials = [{
    label: "3D Printed",
    checked: false,
  },
  {
    label: "Biological Materials",
    checked: false,
  },
  {
    label: "Frangible Materials",
    checked: false,
  },
  {
    label: "Radioactive Materials",
    checked: false,
  },
  {
    label: "Toxic Materials",
    checked: false,
  },
  ]
  valueChecked = false;
  deploymentQuestionnaire: FormGroup;
  electricalDropdown = false;
  checkedElectrical;
  formSubmitted = false;
  buttonSubmit = false;
  submitStatus = "SUBMIT";


  constructor( private formBuilder: FormBuilder) { }

  toggleElectricalOptions() {
    this.electricalDropdown = !this.electricalDropdown;
  }

  deploymentForm() {

}

checkElectrical(electrical) {
  this.deploymentQuestionnaire.patchValue({electrical}, { onlySelf: true, emitEvent: true });
  this.checkedElectrical = electrical; 
}

checkValue(material) {
  material.checked = !material.checked;
  let m = this.materials.filter(m1 => m1.checked).map(m2 => m2.label).join(", ");
  console.log(m);
  this.deploymentQuestionnaire.patchValue({"materials": m}, { onlySelf: true, emitEvent: true });
}

formLog() {

  this.formSubmitted = true;

  setTimeout(() => {
    this.formSubmitted = false;
    this.submitStatus = "SENT!"
    this.buttonSubmit = true;
  }, 2500
  );

  console.log(this.deploymentQuestionnaire.value)
}

  ngOnInit()  {

    this.deploymentQuestionnaire =  this.formBuilder.group({
      form: ['Satellite Deployments'],
      missiondate: [this.date],
      name: [''],
      email: [''],
      phone: [''],
      companyname: [''],
      // Project Summary
      summary: [''],
      missionlength: [''],
      // Viewing Considerations
      nadir: [''],
      zenith: [''],
      fov: [''],
      // Physical Constraints
      mass: [''],
      length: [''],
      height: [''],
      configuration: [''],
      // Materials
      materials: [''],
      // Electrical
      electrical: [''], 
      other_electrical:[''] 
  
    });
  }

  get p() { return this.deploymentQuestionnaire.controls; }

}
