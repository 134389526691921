import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.pug',
  styleUrls: ['./book.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-in', 
                    style({ height: 76, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: 50, opacity: 1 }),
            animate('0.5s ease-out', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BookComponent implements OnInit {

  productId = 0;
  dateId = 0;
  formStep = 0;
  formInput = 0;
  formType;
  bookingTime;
  productStep = 1;
  platformsQuestionnaire: FormGroup;
  products = [{
    
    productimg: "product1",
    productname: "Space Platforms",
    slide: "1"
  },
  {
    
    productimg: "product2",
    productname: "Satellite Deployments",
    slide: "2"
  },
  {
    
    productimg: "product3",
    productname: "In-Space Services",
    slide: "3"
  },
  {
    
    productimg: "product4",
    productname: "Ground Segment Services",
    slide: "4"

  },
  ]

  dates = [{ 
    year: "2021",
    quarter: "Q3",
    id: "1"
  },
  { 
    year: "2021",
    quarter: "Q4",
    id: "2"
  },
  { 
    year: "2022",
    quarter: "Q1",
    id: "3"
  },
  { 
    year: "2022",
    quarter: "Q2",
    id: "4"
  },
  { 
    year: "2022",
    quarter: "Q3",
    id: "5"
  },
  { 
    year: "2022",
    quarter: "Q4",
    id: "6"
  },

  { 
    year: "2023",
    quarter: "Q1",
    id: "7"
  },

  { 
    year: "2023",
    quarter: "Q2",
    id: "8"
  },

  { 
    year: "2023",
    quarter: "Q3",
    id: "9"
  },
  ];

  platformsFormSet = [
  
  {
    label: 'Name',
    formname: 'name',
    placeholder: 'Enter your full name'
  },
  {
    label: 'Role',
    formname: 'role',
    placeholder: 'Enter your role at company'
  },
  {
    label: 'Email',
    formname: 'email',
    placeholder: 'Enter your email address'
  },
  {
    label: 'Phone',
    formname: 'phone',
    placeholder: 'Enter your phone number'
  },
  {
    label: 'Company Name',
    formname: 'companyname',
    placeholder: 'Enter your company name'
  },
  {
    label: 'Company Address',
    formname: 'companyaddress',
    placeholder: 'Enter your company address'
  },
  {
    label: 'Company Type',
    formname: 'companytype',
    placeholder: 'Enter your company type'
  },
  {
    label: 'Payload Type',
    formname: 'payloadtype',
    placeholder: 'Enter your company type'
  },
  {
    label: 'Payload Size',
    formname: 'payloadsize',
    placeholder: 'Enter your payload size'
  },
  {
    label: 'Budget',
    formname: 'payloadbudget',
    placeholder: 'Enter your estimated budget'
  },



]

  dateOptions: OwlOptions = {
    loop: false,
    center: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    items: 1,
    responsive: {
      1100: {
        items: 4
      },
    },
    autoplay: false,
    slideTransition: 'linear',
  };

  customOptions: OwlOptions = {
    loop: false,
    center: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    items: 4,
    autoplay: false,
    slideTransition: 'linear',
}

  formLog() {
    console.log(this.platformsQuestionnaire, this.formType, this.bookingTime)
  }

  getFormType() {

   
  }

  platformsForm() {
    if(this.productId === 0) {
      this.formType = "Space Platforms"
    }

    if(this.productId === 1) {
      this.formType = "Satellite Deployments"
    }
    if(this.productId === 2) {
      this.formType = "In-Space Services"
    }
    if(this.productId === 3) {
      this.formType = "Ground Segment Services"
    }
    if(this.dateId === 0) {
      this.bookingTime = "Q3 2021"
    }
    if(this.dateId === 1) {
      this.bookingTime = "Q4 2021"
    }
    if(this.dateId === 2) {
      this.bookingTime = "Q1 2022"
    }
    if(this.dateId === 3) {
      this.bookingTime = "Q2 2022"
    }
    if(this.dateId === 4) {
      this.bookingTime = "Q3 2022"
    }
    if(this.dateId === 5) {
      this.bookingTime = "Q4 2022"
    }
    if(this.dateId === 6) {
      this.bookingTime = "Q1 2023"
    }
    if(this.dateId === 7) {
      this.bookingTime = "Q2 2023"
    }
    if(this.dateId === 8) {
      this.bookingTime = "Q3 2023"
    }
    
    this.platformsQuestionnaire = this.formBuilder.group({
      type: [this.formType],
      time: [this.bookingTime],
      name: [''],
      role: [''],
      email: [''],
      phone: [''],
      companyname: [''],
      companytype: [''],
      companyaddress: [''],
      payloadtype: [''],
      payloadsize: [''],
      payloadbudget: [''],
    })
  }

  previousFormStep() {
    this.formStep = this.formStep - 1;
  }

  nextFormStep() {
    this.formStep = this.formStep + 1;
  }

  updateProductSlide(i) {
    this.productId = i;
  };

  updateDateSlide(i) {
    this.dateId = i;
  }
  
  constructor( private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.platformsForm();
    this.getFormType();
  }

}
