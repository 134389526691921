import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {
    private subject = new Subject<any>();
    private data = new BehaviorSubject(null);

    constructor() {
    }
    open(message: string, data: any = {}) {
      this.subject.next(message);
      this.data.next(data);
    }

    close(){
      this.subject.next();
      this.data.next(null);
    }

    getMessage(): Observable<any> {
      return this.subject.asObservable();
    }

    getData(): Observable<any> {
      return this.data.asObservable();
    }

    next(message: string, data: any = {}) {
      this.subject.next();
      this.data.next(null);
      this.subject.next(message);
      this.data.next(data);
    }

}
