import { Component, AfterViewInit, Renderer2, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../_services/modal.service';
import { BookmodalComponent } from '../bookmodal/bookmodal.component';


@Component({
  selector: 'modal',
  templateUrl: './modal.component.pug',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {
  page: any;
  @ViewChild('vc', { read: ViewContainerRef, static: true}) myRef;

  pages = {
    bookmodal: BookmodalComponent,
  };

  private subscription: Subscription;

  constructor(
    private renderer: Renderer2,
    public modal: ModalService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngAfterViewInit() {
    this.subscription = this.modal.getMessage().subscribe(page => {
      this.page = page;
      if(page===undefined){
        this.renderer.removeClass(document.body, 'modal-open');
        this.myRef.clear();
      } else {
        this.renderer.addClass(document.body, 'modal-open');

        const component = this.pages[page];
        if (!component) {
          return;
        }
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        const ref = this.myRef.createComponent(factory);
      }
    });
  }
}
