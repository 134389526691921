import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'ground-segment-services-form',
  templateUrl: './ground-segment-services-form.component.pug',
  styleUrls: ['./ground-segment-services-form.component.scss']
})
export class GroundSegmentServicesFormComponent implements OnInit {


  @Input() date;
  materialChecked: false;
  material;
  materialValue;
  materialscheck;
  materialArray;

  electricalOptions =
  ["Nominal (120VDC, 350 watts)", "Maximum (700 watts)", "Other power and electrical considerations"]

  materials = [{
    label: "3D Printed",
    checked: false,
  },
  {
    label: "Biological Materials",
    checked: false,
  },
  {
    label: "Frangible Materials",
    checked: false,
  },
  {
    label: "Radioactive Materials",
    checked: false,
  },
  {
    label: "Toxic Materials",
    checked: false,
  },
  ]
  valueChecked = false;
  groundQuestionnaire: FormGroup;
  materialsArray: FormArray;
  electricalDropdown = false;
  checkedElectrical;
  formSubmitted = false;
  buttonSubmit = false;
  submitStatus = "SUBMIT";


  constructor( private formBuilder: FormBuilder) { }


  toggleElectricalOptions() {
    this.electricalDropdown = !this.electricalDropdown;
  }

  checkElectrical(electrical) {
    this.groundQuestionnaire.patchValue({electrical}, { onlySelf: true, emitEvent: true });
    this.checkedElectrical = electrical; 
  }
  
  checkValue(material) {
    material.checked = !material.checked;
    let m = this.materials.filter(m1 => m1.checked).map(m2 => m2.label).join(", ");
    console.log(m);
    this.groundQuestionnaire.patchValue({"materials": m}, { onlySelf: true, emitEvent: true });
  }

formLog() {
  this.formSubmitted = true;

  setTimeout(() => {
    this.formSubmitted = false;
    this.submitStatus = "SENT!"
    this.buttonSubmit = true;
  }, 2500
  );
  console.log(this.groundQuestionnaire.value)
}

  ngOnInit(){
    this.groundQuestionnaire =  this.formBuilder.group({
      form: ['Ground Segment Services'],
      missiondate: [this.date],
      name: [''],
      email: [''],
      phone: [''],
      companyname: [''],
      // Project Summary
      summary: [''],
      missionlength: [''],
      // Viewing Considerations
      nadir: [''],
      zenith: [''],
      fov: [''],
      // Physical Constraints
      mass: [''],
      length: [''],
      height: [''],
      configuration: [''],
      // Materials
      materials: [''],
      // Electrical
      electrical: [''], 
      other_electrical:[''] 
  
    });

  }

  get p() { return this.groundQuestionnaire.controls; }

}
