import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'in-space-services-form',
  templateUrl: './in-space-services-form.component.pug',
  styleUrls: ['./in-space-services-form.component.scss']
})
export class InSpaceServicesFormComponent implements OnInit {

  @Input() date;

  electricalOptions =
  ["Nominal (120VDC, 350 watts)", "Maximum (700 watts)", "Other power and electrical considerations"]

  materials = [{
    label: "3D Printed",
    checked: false,
  },
  {
    label: "Biological Materials",
    checked: false,
  },
  {
    label: "Frangible Materials",
    checked: false,
  },
  {
    label: "Radioactive Materials",
    checked: false,
  },
  {
    label: "Toxic Materials",
    checked: false,
  },
  ]
  valueChecked = false;
  inspaceQuestionnaire: FormGroup;
  electricalDropdown = false;
  checkedElectrical;
  formSubmitted = false;
  buttonSubmit = false;
  submitStatus = "SUBMIT";

  constructor( private formBuilder: FormBuilder) { }

  inspaceForm() {

}

checkElectrical(electrical) {
  this.inspaceQuestionnaire.patchValue({electrical}, { onlySelf: true, emitEvent: true });
  this.checkedElectrical = electrical; 
}

toggleElectricalOptions() {
  this.electricalDropdown = !this.electricalDropdown;
}

checkValue(material) {
  material.checked = !material.checked;
  let m = this.materials.filter(m1 => m1.checked).map(m2 => m2.label).join(", ");
  console.log(m);
  this.inspaceQuestionnaire.patchValue({"materials": m}, { onlySelf: true, emitEvent: true });
}



formLog() {
  this.formSubmitted = true;

  setTimeout(() => {
    this.formSubmitted = false;
    this.submitStatus = "SENT!"
    this.buttonSubmit = true;
  }, 2500
  );
  console.log(this.inspaceQuestionnaire.value)
}

  ngOnInit() {
    this.inspaceQuestionnaire =  this.formBuilder.group({
      form: ['In-Space Services'],
      missiondate: [this.date],
      name: [''],
      email: [''],
      phone: [''],
      companyname: [''],
      // Project Summary
      summary: [''],
      missionlength: [''],
      // Viewing Considerations
      nadir: [''],
      zenith: [''],
      fov: [''],
      // Physical Constraints
      mass: [''],
      length: [''],
      height: [''],
      configuration: [''],
      // Materials
      materials: [''],
      // Electrical
      electrical: [''], 
      other_electrical:[''] 
  
    });
  }
  get p() { return this.inspaceQuestionnaire.controls; }

}
