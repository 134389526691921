import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconModule } from '@ant-design/icons-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BookComponent } from './book/book.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CubesatComponent } from './cubesat/cubesat.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BookmodalComponent } from './bookmodal/bookmodal.component';
import { ModalService } from './_services/modal.service';
import { ModalComponent } from './modal/modal.component';
import { SpacePlatformsFormComponent } from './_forms/space-platforms-form/space-platforms-form.component';
import { InSpaceServicesFormComponent } from './_forms/in-space-services-form/in-space-services-form.component';
import { GroundSegmentServicesFormComponent } from './_forms/ground-segment-services-form/ground-segment-services-form.component';
import { SatelliteDeploymentsFormComponent } from './_forms/satellite-deployments-form/satellite-deployments-form.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BookComponent,
    CubesatComponent,
    AboutComponent,
    FooterComponent,
    TermsComponent,
    PrivacyComponent,
    BookmodalComponent,
    ModalComponent,
    SpacePlatformsFormComponent,
    InSpaceServicesFormComponent,
    GroundSegmentServicesFormComponent,
    SatelliteDeploymentsFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgScrollbarModule,
    CarouselModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    BookmodalComponent,
  ],
  providers: [
    ModalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
