import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ScrollService } from '../_services/scroll.service';
import { ModalService } from '../_services/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.pug',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit{

  @ViewChild('heroContainer', { static: true }) heroContainer: ElementRef
  constructor(private scrollService: ScrollService, public modal: ModalService) {
    scrollService.currentSection.subscribe(
      (res) => {
        this.currentSection = res.toString();

      });
  }
  currentSection: string;
  globeLoaded = false;


  fadeInGlobe() {
    this.globeLoaded = !this.globeLoaded;
  }

  ngAfterViewInit() {

  }

}
