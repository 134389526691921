import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'space-platforms-form',
  templateUrl: './space-platforms-form.component.pug',
  styleUrls: ['./space-platforms-form.component.scss']
})
export class SpacePlatformsFormComponent implements OnInit {


  @Input() date;

  otherElectrical = false;
  buttonSubmit = false;
  submitStatus = "SUBMIT";
  electricalOptions =
  ["Nominal (120VDC, 350 watts)", "Maximum (700 watts)", "Other power and electrical considerations"]

  materials = [{
    label: "3D Printed",
    checked: false,
  },
  {
    label: "Biological Materials",
    checked: false,
  },
  {
    label: "Frangible Materials",
    checked: false,
  },
  {
    label: "Radioactive Materials",
    checked: false,
  },
  {
    label: "Toxic Materials",
    checked: false,
  },
  ]
  valueChecked = false;
  platformsQuestionnaire: FormGroup;
  electricalDropdown = false;
  checkedElectrical;
  formSubmitted = false;


  constructor( private formBuilder: FormBuilder) { }

  checkValue(material) {
    material.checked = !material.checked;
    let m = this.materials.filter(m1 => m1.checked).map(m2 => m2.label).join(", ");
    console.log(m);
    this.platformsQuestionnaire.patchValue({"materials": m}, { onlySelf: true, emitEvent: true });
  }

  checkElectrical(electrical) {
    this.platformsQuestionnaire.patchValue({electrical}, { onlySelf: true, emitEvent: true });
    this.checkedElectrical = electrical; 
  }


  formLog() {
    this.formSubmitted = true;

    setTimeout(() => {
      this.formSubmitted = false;
      this.submitStatus = "SENT!"
      this.buttonSubmit = true;
    }, 2500
    );

    console.log(this.platformsQuestionnaire.value);
  }

  toggleElectricalOptions() {
    this.electricalDropdown = !this.electricalDropdown;
  }

  ngOnInit() {
    this.platformsQuestionnaire = this.formBuilder.group({
      form: ['Space Platforms'],
      missiondate: [this.date],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      companyname: [''],
      // Project Summary
      summary: [''],
      missionlength: [''],
      // Viewing Considerations
      nadir: [''],
      zenith: [''],
      fov: [''],
      // Physical Constraints
      mass: [''],
      length: [''],
      height: [''],
      configuration: [''],
      // Materials
      materials: [''],
      // Electrical
      electrical: [''],
      other_electrical:['']

    });
  }

  get p() { return this.platformsQuestionnaire.controls; }
}
