import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Scene, PerspectiveCamera, WebGLRenderer, PointLight, SphereGeometry, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { LWOLoader } from 'three/examples/jsm/loaders/LWOLoader'

@Component({
  selector: 'cubesat',
  templateUrl: './cubesat.component.pug',
  styleUrls: ['./cubesat.component.scss']
})
export class CubesatComponent implements AfterViewInit, OnDestroy {

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  scene = new Scene()
  loaded = false;
  cubesat
  renderer: WebGLRenderer
  camera: PerspectiveCamera

  @ViewChild('rendererContainer') rendererContainer: ElementRef

  constructor(private ngRenderer:Renderer2){
    this.renderer = new WebGLRenderer({alpha: true, antialias: true})
  }

  ngAfterViewInit(){
    this.camera = new PerspectiveCamera(75, this.rendererContainer.nativeElement.clientWidth / this.rendererContainer.nativeElement.clientHeight, 0.1, 1000)
    this.camera.position.z = 4.5;
    this.ngRenderer.appendChild(this.rendererContainer.nativeElement, this.renderer.domElement)
    this.startScene();
  }

  startScene() {
    const frontLight = new PointLight(0xFF7744, 2.5)
    frontLight.position.set(-500, 300, 100)
    this.scene.add(frontLight)

    const backLight = new PointLight(0x44ccFF, 0.5)
    backLight.position.set(-200, -300, 0)
    this.scene.add(backLight)

    this.renderer.setSize(this.rendererContainer.nativeElement.clientWidth, this.rendererContainer.nativeElement.clientHeight)
    let loader = new LWOLoader();
    loader.load('/assets/cubesat.lwo', (c) => {
      this.cubesat = new Object3D();
      this.cubesat.add(c.meshes[0]);
      this.cubesat.rotation.x = Math.PI * -0.1
      this.cubesat.rotation.y = Math.PI * 0.3
      this.cubesat.rotation.z = Math.PI * 0.4
      this.loaded = true;
      this.scene.add(this.cubesat);
      this.animate();
    })
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.renderer.setSize(this.rendererContainer.nativeElement.clientWidth, this.rendererContainer.nativeElement.clientHeight)
      this.camera.aspect = this.rendererContainer.nativeElement.clientWidth / this.rendererContainer.nativeElement.clientHeight
      this.camera.updateProjectionMatrix();
    })
  }

  ngOnDestroy(){
    this.resizeSubscription$.unsubscribe()
  }

  animate () {
    this.cubesat.children[0].rotation.y += 0.003;
    // Schedule the next frame.
    requestAnimationFrame(this.animate.bind(this));
    // Draw!
    this.renderer.render(this.scene, this.camera);
  }
}
